import { graphql, useStaticQuery } from 'gatsby'

import type { InstitutionalBanners } from '@plurix/ecom-pages/src/components/sections/Institutional'

interface OurStoresContent {
  name: string
  address: string
  city: string
  neighborhood: string
  phone: string
  openingHours: string
  googleMapsLink: string
  services?: Array<Service>
}

interface Service {
  icon: string
  label: string
}

const useCmsInstitutional = () => {
  const data = useStaticQuery(
    graphql`
      query CmsInstitucionalQuery {
        cmsInstitucional {
          sections {
            data
          }
        }
      }
    `
  )

  const aboutBanners: Array<InstitutionalBanners> =
    data?.cmsInstitucional?.sections?.[0]?.data?.banners

  const aboutContent: string | undefined =
    data?.cmsInstitucional?.sections?.[0]?.data?.content

  const storeBanners: Array<InstitutionalBanners> =
    data?.cmsInstitucional?.sections?.[1]?.data?.banners

  const storeContent: Array<OurStoresContent> =
    data?.cmsInstitucional?.sections?.[1]?.data?.stores

  const privacyBanners: Array<InstitutionalBanners> =
    data?.cmsInstitucional?.sections?.[2]?.data?.banners

  const termsBanners: Array<InstitutionalBanners> =
    data?.cmsInstitucional?.sections?.[3]?.data?.banners

  const attendanceBanners: Array<InstitutionalBanners> =
    data?.cmsInstitucional?.sections?.[4]?.data?.banners

  const attendanceContent: string | undefined =
    data?.cmsInstitucional?.sections?.[4]?.data?.content

  const workWithusBanners: Array<InstitutionalBanners> =
    data?.cmsInstitucional?.sections?.[5]?.data?.banners

  const workWithusContent: string | undefined =
    data?.cmsInstitucional?.sections?.[5]?.data?.content

  const cookiePolicyBanners: Array<InstitutionalBanners> =
    data?.cmsInstitucional?.sections?.[6]?.data?.banners

  return {
    aboutBanners,
    aboutContent,
    storeBanners,
    storeContent,
    privacyBanners,
    termsBanners,
    attendanceBanners,
    attendanceContent,
    workWithusBanners,
    workWithusContent,
    cookiePolicyBanners,
  }
}

export default useCmsInstitutional
