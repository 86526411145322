import React from 'react'

export const LinkedinIcon = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M29.2504 31.9962H2.75039C2.7065 31.9729 2.66046 31.954 2.61291 31.9396C1.8719 31.792 1.20531 31.3911 0.727463 30.8057C0.342517 30.3492 0.156327 29.8062 -0.000793457 29.2467V2.74953C0.021114 2.71595 0.0387867 2.6798 0.0518359 2.64188C0.392788 1.03732 1.6584 0.000885129 3.29953 9.93519e-05C11.7668 9.93519e-05 20.234 9.93519e-05 28.7012 9.93519e-05C28.9923 -0.00206633 29.2824 0.0311812 29.5654 0.0991081C31.0376 0.469209 32.0008 1.74924 32.0008 3.32079C32.0008 11.7679 32.0008 20.2145 32.0008 28.6605C32.0008 28.754 32.0008 28.8475 31.9969 28.9411C31.9419 29.8376 31.5671 30.577 30.908 31.1813C30.4327 31.6198 29.8537 31.8327 29.2504 31.9962ZM27.1827 27.177C27.1874 27.0929 27.1944 27.0324 27.1944 26.9719C27.1944 23.9928 27.1944 21.0139 27.1944 18.0353C27.2009 17.3562 27.0672 16.6832 26.8016 16.0582C25.9147 14.0152 24.3663 12.7713 22.1477 12.5222C20.3888 12.3234 18.9747 13.0833 17.8379 14.3979C17.7766 14.4686 17.7209 14.5448 17.6627 14.6179L17.5889 14.5865V12.8295H12.8297V27.1809H17.603V26.8014C17.603 24.2083 17.603 21.6153 17.603 19.0222C17.603 18.8242 17.6101 18.6293 17.6281 18.4289C17.7586 17.0405 19.3007 16.0111 20.6268 16.4244C21.74 16.7717 22.3913 17.6738 22.3936 18.8965C22.3973 21.5309 22.3973 24.1659 22.3936 26.8014V27.1801L27.1827 27.177ZM4.82752 12.8138V27.1683H9.58515V12.8138H4.82752ZM10.0722 7.20803C10.0756 6.63902 9.91025 6.08177 9.597 5.60677C9.28375 5.13178 8.83669 4.76037 8.31238 4.53954C7.78807 4.3187 7.21005 4.25835 6.65144 4.36612C6.09284 4.47389 5.57876 4.74495 5.17418 5.14499C4.7696 5.54504 4.49272 6.05611 4.37856 6.61357C4.26441 7.17102 4.31813 7.74982 4.53288 8.27675C4.74764 8.80367 5.1138 9.25505 5.58507 9.57381C6.05633 9.89256 6.61151 10.0644 7.1804 10.0675C7.94242 10.07 8.67444 9.77059 9.21636 9.23474C9.75827 8.69889 10.066 7.9702 10.0722 7.20803V7.20803Z"
      fill="#0A66C2"
    />
  </svg>
)
