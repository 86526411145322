import React from 'react'

export const WorWithusMock = () => {
  return (
    <div className="workwithus-container">
      <p>
        Trabalhar no Boa Supermercados é fazer parte de uma empresa de sucesso.
        Mais do que isso, uma família.
      </p>
      <p>
        Dinamismo, ética e espírito empreendedor são características de nossos
        colaboradores.
      </p>
      <p>
        Se você se identifica e quer fazer parte de nossa equipe, envie seu
        currículo para{' '}
        <a href="mailto:trabalheconosco@smboa.com.br">
          trabalheconosco@smboa.com.br
        </a>
      </p>
    </div>
  )
}
